.app {
    height: 100%;
    width: 100%;
}

.app-nav {
    height: 5.2rem;
    max-height: 5.2rem;

    .nav-icon {
        font-size: 1.2em;
    }

    .nav-item .dropdown-menu {
        border-top: 0 !important;
        border-radius: 0;
        margin-top: .6rem !important;
    }

    .notification-badge {
        position: absolute !important;
        top: -.5rem !important;
        right: -1rem !important;
    }

    .app-navigation-list {
        > .nav-item {
            &:first-child {
                .border-left {
                    border-left: none !important;
                }
            }
        }
    }
}


.h-2rem {
    height: 2rem;
    max-height: 2rem;
}

.min-w-3rem {
    min-width: 3rem;
}

.max-w-5rem {
    width: auto;
    max-width: 5rem;
    hyphens: auto;
}

.border-dark {
    border-color: #6d7985 !important;
}

.border-ci-primary-contrast-light {
    border-color: rgba(255, 255, 255, .3) !important;
}

.border-ci-primary-contrast {
    border-color: white !important;
}

.extra-small {
    font-size: 0.75em;
    line-height: 1.2em;
}

.app-main {
    height: calc(100vh - 6rem);
    max-height: calc(100vh - 6rem);
}

.nounderline {
    text-decoration: none !important;
}

.profile-photo {
    width: 4rem;
    height: 4rem;
}

.logo {
    height: 2.5rem;
}

.logo-login {
    height: 2.5rem;
}

.tenant-logo {
    max-height: 3.5rem;
    max-width: 10rem;
    width: auto;
    height: auto;
}

.tenant-logo-login {
    max-height: 6rem;
    max-width: 15rem;
    width: auto;
    height: auto;
}

.square-fa, .square-fa-small, .square-fa-extra-small {
    display: flex;
    justify-content: center;
    align-items: center;
}

.square-fa {
    height: 2rem !important;
    width: 2rem !important;

    .fal, .fas, .far {
        font-size: 1rem !important;
    }
}

.square-fa-small, {
    height: 1rem !important;
    width: 1rem !important;

    .fal, .fas, .far {
        font-size: 0.53rem !important;
    }
}

.square-fa-extra-small {
    height: 0.75rem !important;
    width: 0.75rem !important;

    .fal, .fas, .far {
        font-size: 0.35rem !important;
    }
}

.logomobile {
    height: 2.5rem;
}

.login {
    height: 100vh;
}

.thickhr {
    border-top: 2px solid rgba(0, 0, 0, 0.5);
}

.dropdown-menu-nav {
    width: 20rem;
    padding: 1rem;
}

.orderexpand {
    box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.247);
}

.order-body {
    height: calc(100vh - 10rem);
    max-height: calc(100vh - 10rem);
}

.order-sidebar {
    overflow-y: auto;
    overflow-x: hidden;
}

.order-content {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100% !important;
    height: 100% !important;
    padding: 0;
    width: 100% !important;
    max-width: 100% !important;
}

.cat-content {
    padding-top: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.footer-block {
    height: 4rem;
}

.order-footer {
    height: 4rem;
    max-height: 4rem;
}

.genmenu {
    margin-left: -1px;
    margin-bottom: 0 !important;
    border-radius: 0;
}

.order-list {
    height: 60vh;
    overflow-y: auto;
}

.file-list {
    height: 60vh;
    overflow-y: auto;
}

.collapsed > .open,
:not(.collapsed) > .closed {
    display: none;
}

.table-sticky thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
}

.table-sticky thead th {
    border-top: 0;
    border-bottom: 0;
}

.table-sticky tbody {
    overflow-y: auto;
}

.table-sticky th,
.table-sticky td {
    vertical-align: middle;
}

.multiunit-table-container {
    min-height: 200px;
    margin: 0;
}

.multiunit-tbody {
    max-height: 100%;
    overflow-y: auto;
}

.singleunit-card {
    max-height: 100%;
    overflow-y: auto;
}

.recursive-table tbody > .collapsing {
    transition: none !important;
}

.recursive-row-details {
    box-shadow: inset 0 0 15px 15px rgba(0, 0, 0, 0.9);
}

.cursor-pointer {
    cursor: pointer;
}

.gen-list-body {
    margin-bottom: 4rem;
}

.multiple-unit-overlay {
    max-height: 100% !important;
    height: 100% !important;
    width: 100% !important;
    overflow-y: hidden;
}

.multiple-unit-section-transition {
    transition: all 200ms ease-out;
}

.min-w-0 {
    min-width: 0 !important;
}

.p-5px {
    padding: 5px !important;
}

.faq-search {
    padding-top: calc(1.5rem);
    padding-bottom: calc(1.5rem);
    width: 100%;
}

.faq-list {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.list-group-multiple {
    display: contents;
}

.handle-column {
    width: 10px;
}

.handle {
    cursor: grab;
}

.app-content-wrapper {
    height: calc(100vh - 6rem) !important;
    width: 100% !important;
}

.parameter-animated {
    transition: all 400ms;
    box-shadow: 0 0 10px 3px rgba(46, 116, 181, 0);
}

.parameter-highlight {
    border-color: rgba(46, 116, 181, 1);
    box-shadow: 0 0 10px 3px rgba(46, 116, 181, 0.5);
}

.highlighted-single-unit {
    margin-left: -8%;
}

.highlighted-unit-table {
    margin-right: 8%;
}

.z-900 {
    z-index: 900;
}

.tox-statusbar__path { /* CSS */
    display: none !important;
}


$primaryDark: darken($primary, 10%);
$primaryLight: lighten($primary, 10%);
$secondaryDark: darken($secondary, 10%);
$secondaryLight: lighten($secondary, 10%);

.border-ci-primary {
    border-color: $primary !important;
}

.text-ci-primary {
    color: $primary !important;
}

.bg-ci-primary {
    background-color: $primary !important;
}

.bg-ci-primary-dark {
    background-color: $primaryDark !important;
}

.bg-ci-primary-light {
    background-color: $primaryLight !important;
}

.bg-ci-primary-contrast {
    background-color: #fff !important;
}

.bg-ci-primary-dark-contrast {
    background-color: #fff !important;
}

.bg-ci-primary-light-contrast {
    background-color: #fff !important;
}

.btn-ci-primary {
    background-color: $primary !important;
    border-color: $primary !important;
    color: #fff !important;
}

.outline-ci-primary {
    background-color: transparent !important;
    border-color: $primary !important;
    color: $primary !important;
}

.btn-outline-ci-primary {
    background-color: transparent !important;
    border-color: $primary !important;
    color: $primary !important;
}

.outline-ci-secondary {
    background-color: transparent !important;
    border-color: $secondary !important;
    color: $secondary !important;
}

.btn-ci-secondary {
    background-color: $secondary !important;
    border-color: $secondary !important;
    color: #fff !important;
}

.btn-outline-ci-secondary {
    background-color: transparent !important;
    border-color: $secondary !important;
    color: $secondary !important;
}

.border-ci-secondary {
    border-color: $secondary !important;
}

.text-ci-secondary {
    color: $secondary !important;
}

.bg-ci-secondary {
    background-color: $secondary !important;
}

.bg-ci-secondary-dark {
    background-color: $secondaryDark !important;
}

.bg-ci-secondary-light {
    background-color: $secondaryLight !important;
}

.bg-ci-secondary-contrast {
    background-color: #fff !important;
}

.bg-ci-secondary-dark-contrast {
    background-color: #fff !important;
}

.bg-ci-secondary-light-contrast {
    background-color: #fff !important;
}

.border-ci-primary-dark, .border-primary-dark {
    border-color: $primaryDark !important;
}

.text-ci-primary-dark, .text-primary-dark {
    color: $primaryDark !important;
}

.bg-ci-primary-dark, .bg-primary-dark {
    background: $primaryDark !important;
}

.border-ci-primary-light, .border-primary-light {
    border-color: $primaryLight !important;
}

.text-ci-primary-light, .text-primary-light {
    color: $primaryLight !important;
}

.bg-ci-primary-light, .bg-primary-light {
    background-color: $primaryLight !important;
}

.border-ci-secondary-dark, .border-secondary-dark {
    border-color: $secondaryDark !important;
}

.text-ci-secondary-dark, .text-secondary-dark {
    color: $secondaryDark !important;
}

.bg-ci-secondary-dark, .bg-secondary-dark {
    background-color: $secondaryDark !important;
}

.border-ci-secondary-light, .border-secondary-light {
    border-color: $secondaryLight !important;
}

.text-ci-secondary-light, .text-secondary-light {
    color: $secondaryLight !important;
}

.bg-ci-secondary-light, .bg-secondary-light {
    background-color: $secondaryLight !important;
}

.text-ci-primary-contrast {
    color: #fff !important;
}

.text-ci-primary-dark-contrast {
    color: #fff !important;
}

.text-ci-primary-light-contrast {
    color: #fff !important;
}

.text-ci-secondary-contrast {
    color: #fff !important;
}

.text-ci-secondary-dark-contrast {
    color: #fff !important;
}

.text-ci-secondary-light-contrast {
    color: #fff !important;
}

.list-group-minimal {
    > .list-group-item {
        &:first-child {
            border-top: none !important;
        }

        &:last-child {
            border-bottom: none !important;
        }
    }
}

.bg-ci-primary {
    background-color: $primary !important;
}

.bg-ci-secondary {
    background-color: $secondary !important;
}

.text-ci-primary {
    color: $primary !important;
}

.text-ci-secondary {
    color: $secondary !important;
}

.border-ci-primary {
    border-color: $primary !important;
}

.border-ci-secondary {
    border-color: $secondary !important;
}
