// Fonts
@import "@fontsource/nunito";

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Bootstrap-Vue
@import "~bootstrap-vue";

// Bootstrap Material design
//@import '~bootstrap-material-design/scss/bootstrap-material-design';

// elements
@import "elements/card";
@import "elements/navbar";
@import "elements/buttons";
@import "elements/transitions";

// fontawesome pro
@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

// animate.css
@import "~animate.css";

// custom
@import "custom/common";
@import "custom/app";

// select2 uncomment and include in package.json when needed
// @import "~select2";
// @import "~@ttskch/select2-bootstrap4-theme/src/layout";

// vue-select2
//@import "~vue-select/src/scss/vue-select";
