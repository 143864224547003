.navbar {
    font-weight: 600;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

/* Edit Dropdown icon here  */
@media (min-width: 992px) {
    .nav-nav {
        .dropdown-toggle {
            border: none;
            margin-top: 0.5rem;
            border-top: 1px solid #f2f2f2 !important;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        }
    }
}

.nav-item {
    .dropdown-menu {
        border: none;
        margin-top: 0.5rem;
        border-top: 1px solid #f2f2f2 !important;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        width: 20rem;
        padding: 1rem;
        position: absolute;
    }
    .dropdown-menu-lang {
        .dropdown-menu {
            border: none;
            margin-top: 0.5rem;
            border-top: 1px solid #f2f2f2 !important;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
            width: 10rem;
            padding: 1rem;
            position: absolute;
        }
    }
}

.nav-link {
    .nav-icon {
        font-size: 1.4rem;
    }
}
