.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
}

.sticky-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 999;
}

.fixed-bottom {
    position: fixed;
    bottom: 0;
    z-index: 999;
}

.dd-upload-form {
    min-width: 600px !important;
}

.page-loader-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    z-index: 1110;
    background-image: url("/assets/images/loaderbg.png");
}

.loader {
    width: 100%;
    height: 100%;
    border: 5px solid transparent;
    border-top: 5px solid #2e74b5;
    border-radius: 50%;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

.page-loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100050;
    opacity: 0.99;
    /* background-color: rgb(0, 0, 0); */
    background-color: #fff;
}

.unit-loader-content {
    width: 50px;
    height: 50px;
    z-index: 9999;
    background-image: url("/assets/images/unit-loader-bg.png");
}

.unit-loader {
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid #2e74b5;
    border-radius: 50%;
    animation: rotate 1s linear infinite;
}

.collapsed > .not-collapsed,
.not-collapsed > .collapsed {
    display: none;
}

form.adjacent-fields > .form-group {
    margin-bottom: -1px !important;
}

.file-manager-container {
    min-height: 600px;
}

.fm-navbar > .justify-content-between > .col-auto > .btn-group:last-child {
    display: none !important;
}

.fm-info-block {
    border-bottom: 0 !important;
}

.h-4rem {
    height: 4rem;
    max-height: 4rem;
}

.h-6rem {
    height: 6rem;
    max-height: 6rem;
}

.h-100vh-10rem {
    height: calc(100vh - 10rem);
    max-height: calc(100vh - 10rem);
}

.h-100vh-12rem {
    height: calc(100vh - 12rem);
    max-height: calc(100vh - 12rem);
}

.vue-treeselect-wrapper {
    padding: 0 !important;
    background: #fff !important;
}

.vue-treeselect__control {
    border: none !important;
}

.vue-treeselect__x-container {
    color: #495057 !important;
}

.vue-treeselect__control-arrow {
    color: #495057 !important;
}

.overflow-xysn {
    overflow-y: scroll;
    overflow-x: none;
}

.notification-badge {
    position: absolute;
    top: 0;
    right: -10px;
    padding: 5px 7px !important;
    border-radius: 50% !important;
    color: white;
}

.modal-xxl {
    max-width: 85%;
}

.mh-600px {
    max-height: 600px !important;
}

.mh-400px {
    max-height: 400px !important;
}

.h-max {
    height: 100px;
    max-height: 100% !important;
}

.b-table-sticky-header {
    max-height: 600px !important;
}

.mb-n1px {
    margin-bottom: -1px !important;
}

.mt-n1px {
    margin-top: -1px;
}

.mx-n1px {
    margin-left: -1px;
    margin-right: -1px;
}

.my-n1px {
    margin-top: -1px;
    margin-bottom: -1px;
}

.m-n1px {
    margin: -1px;
}

.px-15px {
    padding-left: 15px;
    padding-right: 15px;
}

.pl-15px {
    padding-left: 15px;
}

.pr-15px {
    padding-right: 15px;
}

.min-h-200px {
    min-height: 200px;
}

.max-w-150px {
    max-width: 150px;
}

.max-w-250px {
    max-width: 250px;
}

.carousel-inner {
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.carousel-caption {
    position: static;
    color: #000000;
    text-align: center;
}

.carousel-indicators,
.carousel-control-next,
.carousel-control-prev {
    bottom: 50px;
}

.mh-250px {
    max-height: 250px;
}

.z-10000 {
    z-index: 10000 !important;
}
