// Body
//$body-bg: #f7f9fb;
$body-bg: #fff;
//$line-height-base: 1.5 !default;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
//$font-family-sans-serif: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;
//$font-family-sans-serif: "Roboto", sans-serif;

//$font-size-base: 0.9rem;
$font-size-base: 1rem !default;
$line-height-base: 1.6 !default;
$font-size-lg: $font-size-base * 1.25 !default;

// Colors
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(
    (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
    ),
    $grays
);

$blue: #2e74b5 !default;
$indigo: #6574cd !default;
$purple: #9561e2 !default;
$pink: #f66d9b !default;
$red: #e3342f !default;
$orange: #f6993f !default;
$yellow: #ffed4a !default;
$green: #38c172 !default;
$teal: #4dc0b5 !default;
$cyan: #6cb2eb !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
    (
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "gray": $gray-600,
        "gray-dark": $gray-800
    ),
    $colors
);

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark
    ),
    $theme-colors
);

// Components
// $border-color: $gray-800 !default;

// Cards
$card-spacer-x: 0.9375rem;
$card-spacer-y: 0.625rem;
$card-cap-bg: #fbfbfb;
$card-border-color: #e8eced;

// Borders
$border-radius: 0.125rem;
$border-radius-lg: 0.2rem;
$border-radius-sm: 0.15rem;

// Navigation
$nav-link-padding-y: 1rem !default;
$nav-link-padding-x: 1rem !default;

$nav-pills-border-radius: 0;
$navbar-dark-color: rgba($white, 1) !default;
$navbar-dark-toggler-border-color: rgba($white, 1) !default;
$navbar-dark-hover-color: rgba($white, 1) !default;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) / 2 !default;

// Input
$input-border-color: $gray-600 !default;

//vs-select
$vs-colors: (
    lightest: rgba(60, 60, 60, 0.26),
    light: rgba(60, 60, 60, 0.5),
    dark: #333,
    darkest: rgba(0, 0, 0, 0.15)
) !default;

//  Global Component Variables
$vs-component-line-height: $line-height-base;
//$vs-component-placeholder-color: inherit !default;

//  Active State
$vs-state-active-bg: $blue;
$vs-state-active-color: $white;

//  Disabled State
$vs-state-disabled-bg: $gray-200;
$vs-state-disabled-color: map_get($vs-colors, "light") !default;
$vs-state-disabled-controls-color: map_get($vs-colors, "light") !default;
$vs-state-disabled-cursor: not-allowed !default;

//  Borders
$vs-border-radius: $border-radius;
$vs-border-color: $input-border-color;

//  Component Controls: Clear, Open Indicator
$vs-controls-color: $input-border-color;
$vs-controls-size: 1 !default;
$vs-controls-deselect-text-shadow: 0 1px 0 #fff;

//  Selected
$vs-selected-bg: #f0f0f0 !default;
$vs-selected-border-color: $input-border-color;
$vs-selected-border-style: solid;
$vs-selected-border-width: 1px;

//  Dropdown
$vs-dropdown-z-index: 1000 !default;
$vs-dropdown-min-width: 160px !default;
$vs-dropdown-max-height: 350px !default;
$vs-dropdown-box-shadow: 0px 2px 6px 0px map_get($vs-colors, "darkest") !default;
$vs-dropdown-bg: #fff !default;

// Images Temporary
$login-image: "https://source.unsplash.com/K4mSJ7kc0As/600x800" !default;
$register-image: "https://source.unsplash.com/Mv9hjnEUHR4/600x800" !default;
$password-image: "https://source.unsplash.com/oWTW-jNGl9I/600x800" !default;

// table
// $table-border-color: $border-color !default;
